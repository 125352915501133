$.fn.createMetadataBlock = function () {
	let swaiedMetadataBlock = $('<div/>', {
		class: 'swaied-metadata',
		html:
			'<div class="swaied-block-metadata" data-block="metatags">' +
				'<div class="swaied-block-wrap meta-title">' +
					'<span class="badge-title">' +
						'Meta Title' +
					'</span>' +
					'<span class="badge-count">' +
						'<span>0</span>' +
						'Characters' +
					'</span>' +
					'<p contenteditable="true"></p>' +
					'<button class="btn btn-default btn-copy-meta">' +
						'<svg><use xlink:href="#swaied-copy"></use></svg>' +
						'Copy' +
					'</button>' +
				'</div>' +
				'<div class="swaied-block-wrap meta-description">' +
					'<span class="badge-title">' +
						'Meta Description' +
					'</span>' +
					'<span class="badge-count">' +
						'<span>0</span>' +
						'Characters' +
					'</span>' +
					'<p contenteditable="true"></p>' +
					'<button class="btn btn-default btn-copy-meta">' +
						'<svg><use xlink:href="#swaied-copy"></use></svg>' +
						'Copy' +
					'</button>' +
				'</div>' +
			'</div>',
	})

	swaiedMetadataBlock.insertBefore($editor)
}