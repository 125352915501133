$.fn.createEditorOutsideBlock = function () {
	let swaiedOutsideBlock = $('<div/>', {
		class: 'swaied-outside-block',
	})

	let btnAiWrite = $('<button/>', {
		class: 'btn btn-aiwrite',
		text: '✨ AI Write',
	})

	let aiwriteFormChoice = $('<div/>', {
		class: 'aiwrite-form-choice',
		html:
			'<div class="aiwrite-form-control">' +
				'<input type="text" name="aiwriteInput" class="aiwrite-input" placeholder="Tell us how to edit selected text"/>' +
				'<button class="btn btn-primary aiwrite-submit" aria-label="Continue writing">' +
					'<svg><use xlink:href="#swaied-check-circle-fill"></use></svg>' +
				'</button>' +
				'<div class="dropdown-container aiwrite-dropdown">' +
					'<button class="btn btn-settings btn-dropdown-toggle">' +
						'<svg><use xlink:href="#swaied-settings"></use></svg>' + 
						'Improvement Tools' +
						'<svg><use xlink:href="#swaied-dropdown"></use></svg>' +
					'</button>' +
					'<div class="dropdown-menu">' +
						'<button class="btn btn-improve-write">' +
							'<svg><use xlink:href="#swaied-journal-check"></use></svg>' +
							'Improve Writing' +
						'</button>' +
						'<div class="dropdown-container-lvl2">' +
							'<button class="btn btn-change-tvoice btn-dropdown-toggle-lvl2">' +
								'<svg><use xlink:href="#swaied-soundwave"></use></svg>' +
								'Change Tone of Voice' +
								'<svg><use xlink:href="#swaied-dropdown"></use></svg>' +
							'</button>' +
							'<div class="dropdown-menu-lvl2">' +
								'<span class="separator">' +
									'Match Brand of Voice' +
								'</span>' +
								'<button class="btn">' +
									'My Company 1' +
								'</button>' +
								'<button class="btn">' +
									'My Instagram' +
								'</button>' +
								'<span class="separator">' +
									'Select Tone' +
								'</span>' +
								'<button class="btn">' +
									'None' +
								'</button>' +
								'<button class="btn">' +
									'Friendly' +
								'</button>' +
								'<button class="btn">' +
									'Professional' +
								'</button>' +
								'<button class="btn">' +
									'Informational' +
								'</button>' +
								'<button class="btn">' +
									'Transactional' +
								'</button>' +
							'</div>' +
						'</div>' +
						'<button class="btn btn-change-length">' +
							'<svg><use xlink:href="#swaied-change-length"></use></svg>' +
							'Change Length' +
						'</button>' +
						'<button class="btn btn-rprps-content">' +
							'<svg><use xlink:href="#swaied-arrow-left-right"></use></svg>' +
							'Repurpose Content' +
						'</button>' +
						'<button class="btn btn-translate">' +
							'<svg><use xlink:href="#swaied-translate"></use></svg>' +
							'Translate' +
						'</button>' +
						'<span class="separator">' +
							'Suggested' +
						'</span>' +
						'<button class="btn btn-flat-washer-materials">' +
							'<svg><use xlink:href="#swaied-file-text"></use></svg>' +
							'Flat Washer Materials' +
						'</button>' +
						'<button class="btn btn-countersunk-washers">' +
							'<svg><use xlink:href="#swaied-file-text"></use></svg>' +
							'Countersunk Washers' +
						'</button>' +
						'<button class="btn btn-rubber-washers">' +
							'<svg><use xlink:href="#swaied-file-text"></use></svg>' +
							'Rubber Washers' +
						'</button>' +
						'<button class="btn btn-wave-spring-washers">' +
							'<svg><use xlink:href="#swaied-file-text"></use></svg>' +
							'Wave Spring Washers' +
						'</button>' +
					'</div>' +
				'</div>' +
			'</div>',
	})

	let aiwriteFormGeneration = $('<div/>', {
		class: 'aiwrite-form-generation',
		html:
			'<div class="aiwrite-form-control">' +
				'<p class="aiwrite-gen-text"></p>' +
				'<span class="aiwrite-loading"></span>' +
				'<span class="aiwrite-notice">' +
					'Stop generating' +
					'<span>' +
						'ESC' +
					'</span>' +
				'</span>' +
			'</div>' +
			'<div class="aiwrite-form-buttons">' +
				'<button class="btn btn-default aiwrite-try-again">' +
					'<svg><use xlink:href="#swaied-arrow-repeat"></use></svg>' +
					'Try Again' +
				'</button>' +
				'<button class="btn btn-primary aiwrite-replace">' +
					'Replace' +
				'</button>' +
			'</div>',
	})

	let swaiedBlockForm = $('<div/>', {
		class: 'swaied-block-wrap swaied-block-form',
		attr: {'data-insert-index': '-1'},
		html: 
			'<div class="swaied-form-control">' +
				'<input type="text" name="swaiedTextInput" class="swaied-text-input" placeholder="Type in your text or select option below" />' +
				'<button class="btn btn-primary swaied-form-submit" aria-label="Submit">' +
					'<svg><use xlink:href="#swaied-check-circle-fill"></use></svg>' +
				'</button>' +
			'</div>' +
			'<div class="swaied-block-form-buttons">' +
				'<div class="dropdown-container swaied-block-dropdown">' +
					'<button class="btn btn-default btn-suggested btn-dropdown-toggle">' +
						'Suggested' +
						'<svg><use xlink:href="#swaied-dropdown"></use></svg>' +
					'</button>' +
					'<div class="dropdown-menu">' +
						'<button class="btn btn-flat-washer-materials">' +
							'<svg><use xlink:href="#swaied-file-text"></use></svg>' +
							'Flat Washer Materials' +
						'</button>' +
						'<button class="btn btn-countersunk-washers">' +
							'<svg><use xlink:href="#swaied-file-text"></use></svg>' +
							'Countersunk Washers' +
						'</button>' +
						'<button class="btn btn-rubber-washers">' +
							'<svg><use xlink:href="#swaied-file-text"></use></svg>' +
							'Rubber Washers' +
						'</button>' +
						'<button class="btn btn-wave-spring-washers">' +
							'<svg><use xlink:href="#swaied-file-text"></use></svg>' +
							'Wave Spring Washers' +
						'</button>' +
					'</div>' +
				'</div>' +
				'<button class="btn btn-default btn-continue-writing" tabindex="-1">' +
					'✨ Continue Writing' +
				'</button>' +
				'<button class="btn btn-default">' +
					'<svg><use xlink:href="#swaied-h2"></use></svg>' +
					'Generate Subtitle' +
				'</button>' +
				'<button class="btn btn-default" id="addImage">' +
					'<svg><use xlink:href="#swaied-insert-image"></use></svg>' +
					'Add Image' +
				'</button>' +
				'<button class="btn btn-default" id="addVideo">' +
					'<svg><use xlink:href="#swaied-youtube"></use></svg>' +
					'Add Video' +
				'</button>' +
				'<button class="btn btn-default">' +
					'<svg><use xlink:href="#swaied-card-text"></use></svg>' +
					'Add Block' +
				'</button>' +
			'</div>',
	})

	let swaiedBlockFormGeneration = $('<div/>', {
		class: 'swaied-block-wrap swaied-aiwrite-form-generation',
		html:
			'<div class="aiwrite-form-control">' +
				'<p class="aiwrite-gen-text"></p>' +
				'<span class="aiwrite-loading"></span>' +
					'<span class="aiwrite-notice">' +
						'Stop generating' +
					'<span>' +
						'ESC' +
					'</span>' +
				'</span>' +
			'</div>' +
			'<div class="aiwrite-form-buttons">' +
				'<button class="btn btn-default aiwrite-try-again">' +
					'<svg><use xlink:href="#swaied-arrow-repeat"></use></svg>' +
					'Try Again' +
				'</button>' +
				'<button class="btn btn-primary aiwrite-replace">' +
					'Replace' +
				'</button>' +
			'</div>',
	})

	swaiedOutsideBlock.append(
		btnAiWrite,
		aiwriteFormChoice,
		aiwriteFormGeneration,
		swaiedBlockForm,
		swaiedBlockFormGeneration
	)

	// swaiedOutsideBlock.insertAfter('.body')
	$(document).find('.section-editor').append(swaiedOutsideBlock)
}
