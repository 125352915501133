// mouse events
$(document)
	.on('click', '#btnSeo', function (e) {
		let t = $(this),
			body = $(document).find('.body'),
			sidebar = $(document).find('#rightFixedBar')

		t.toggleClass('active')

		if (t.hasClass('active')) {
			body.addClass('sidebar-visible')
			sidebar.addClass('show')
		} else {
			body.removeClass('sidebar-visible')
			sidebar.removeClass('show')
			setTimeout(() => {
				sidebar
					.find('.sidebar-item.seo-score')
					.removeClass('sidebar-item-active')
					.hide()
				sidebar
					.find('.sidebar-item.keywords')
					.addClass('sidebar-item-active')
					.show()
			}, 300)
		}
	})
	.on('click', '.sidebar-item .btn-close', function (e) {
		let btn = $(document).find('#btnSeo'),
			body = $(document).find('.body'),
			sidebar = $(document).find('#rightFixedBar')

		btn.removeClass('active')
		body.removeClass('sidebar-visible')
		sidebar.removeClass('show')
		setTimeout(() => {
			sidebar
				.find('.sidebar-item.seo-score')
				.removeClass('sidebar-item-active')
				.hide()
			sidebar
				.find('.sidebar-item.keywords')
				.addClass('sidebar-item-active')
				.show()
		}, 300)
	})
	.on('click', '.btn-dropdown-toggle', function (e) {
		let t = $(this)
		if (t.hasClass('btn-promotions')) {
			hideShowDropdownMenu(t, 'flex', 'both')
		} else if (t.hasClass('btn-seo')) {
			let $parent = t.parent(),
				$dropMenu = $parent.children('.dropdown-menu.keywords'),
				$dropMenuAll = $parent.children('.dropdown-menu')
			dropdownMenuOpen(t, $parent, $dropMenu, 'flex')
			dropdownMenuClose(t, $parent, $dropMenuAll)
		} else {
			hideShowDropdownMenu(t, 'block', 'both')
		}

		if (t.parents('.swaied-block-wrap').length > 0) {
			window.getSelection().removeAllRanges()
		}
	})
	.on('click', '.btn-dropdown-toggle-lvl2', function (e) {
		hideShowDropdownMenuLvl2($(this), 'block', 'both')
	})
	.on('mouseenter', '.dropdown-history .btn-history', function (e) {
		hideShowDropdownMenu($(this), 'block', 'show')
	})
	.on('mouseleave', '.dropdown-history .btn-history', function (e) {
		hideShowDropdownMenu($(this), 'block', 'hide')
	})
	.on('click', '#sendKeyword', function (e) {
		let keyword = $(document).find('#mainKeyword')
		if (keyword.val().length > 0) showSeoScore($(this))
	})
	.on('click', '.dropdown-stat .dropdown-menu .btn', function (e) {
		let t = $(this)
		let text = t.text()
		let svg = '<svg><use xlink:href="#swaied-dropdown"></use></svg>'
		$('#btnDisplayStat').html(text + svg)
		t.parent().find('.btn').removeClass('active')
		t.addClass('active')

		let container = t.parents('.dropdown-container')
		container.find('.dropdown-menu').removeClass('dropdown-open-active')
		container.find('.btn-dropdown-toggle').removeClass('active')
	})
	.on('click', '.dropdown-copy .dropdown-menu .btn', function (e) {
		let t = $(this)
		let text = t.html()
		t.addClass('copied')
			.css({
				'pointer-events': 'none',
			})
			.html(
				'<svg><use xlink:href="#swaied-check-circle-fill"></use></svg> Copied'
			)

		setTimeout(() => {
			t.removeClass('copied').html(text).css({
				'pointer-events': 'auto',
			})
			let container = t.parents('.dropdown-container')
			container.find('.dropdown-menu').removeClass('dropdown-open-active')
			container.find('.btn-dropdown-toggle').removeClass('active')
		}, 1000)

		if (t.hasClass('btn-copy-html')) {
			let html = $editor.html()
			copyToClipboard(html)
		}

		if (t.hasClass('btn-copy-text')) {
			let html = $editor.text()
			copyToClipboard(html)
		}
	})
	.on('click', '.btn-copy-block', function (e) {
		let t = $(this)
		let text = t.html()
		t.addClass('copied')
			.css({
				'pointer-events': 'none',
			})
			.html(
				'<svg><use xlink:href="#swaied-check-circle-fill"></use></svg> Copied'
			)

		setTimeout(() => {
			t.removeClass('copied').html(text).css({
				'pointer-events': 'auto',
			})
			let container = t.parents('.dropdown-container')
			container.find('.dropdown-menu').removeClass('dropdown-open-active')
			container.find('.btn-dropdown-toggle').removeClass('active')
		}, 1000)

		let html = t.parents('.swaied-block-wrap').children()[3].outerHTML
		copyToClipboard(html)
	})
	.on('click', 'input[type="checkbox"]', function () {
		let t = $(this)
		let dataTarget = t.attr('data-target')
		let items = $(document).find('*[data-block="' + dataTarget + '"]')

		if (t.is(':checked')) {
			items.show(300)
		} else {
			items.hide(300)
		}
	})
	.on('click', 'input[name="metatags"]', function () {
		let t = $(this),
			i = $(document).find('.swaied-placeholder').attr('data-target-index'),
			text = $editor.children().eq(i).text().length

		if (t.is(':checked')) {
			$(document).find('.swaied-start-preview').removeClass('metadata-hidden')
		} else {
			$(document).find('.swaied-start-preview').addClass('metadata-hidden')
		}

		$.fn.setCurPlaceholderPos(i, 'hide')
		setTimeout(() => {
			if (text == 0) {
				$.fn.setCurPlaceholderPos(i, 'show')
			}
		}, 300)
	})
	.on('click', 'input[name="citations"]', function () {
		let t = $(this)

		if (t.is(':checked')) {
			$editor.find('sup').show()
		} else {
			$editor.find('sup').hide()
		}
	})
	.on('click', '.btn-copy-meta', function () {
		let t = $(this)
		let text = t.html()
		t.addClass('copied')
			.css({
				'pointer-events': 'none',
			})
			.html(
				'<svg><use xlink:href="#swaied-check-circle-fill"></use></svg> Copied'
			)

		setTimeout(() => {
			t.removeClass('copied').html(text).css({
				'pointer-events': 'auto',
			})
		}, 1000)

		let metaText = t.parent().find('p').text()
		navigator.clipboard.writeText(metaText)
	})
	.on('click', '.btn-remove-block', function () {
		let t = $(this).parents('.swaied-block-wrap'),
			i = t.index(),
			l = $editor.children().length

		t.remove()
		$(document).find('textarea[name="swaied"]').prop('value', $editor.html())
		$editor.trigger('tbwchange')

		if (l > 1 && i != 0) {
			$.fn.setCurCaretPos($editor.children()[+i - 1])
		} else if (l > 1 && i == 0) {
			$.fn.setCurCaretPos($editor.children()[+i])
		}
	})
	.on('click', '.btn-duplicate-block', function () {
		let t = $(this).parents('.swaied-block-wrap'),
			i = t.index(),
			editable = t.attr('contenteditable'),
			$btn = t.find('.btn-more'),
			$parent = $btn.parent(),
			$dropMenu = $parent.children('.dropdown-menu')

		dropdownMenuClose($btn, $parent, $dropMenu)
		setTimeout(() => {
			$(
				'<div class="swaied-block-wrap" contenteditable="' +
					editable +
					'">' +
					t.html() +
					'</div>'
			).insertAfter($editor.children()[i])
			// $(t[0].outerHTML).insertAfter($editor.children()[i])
			$(document).find('textarea[name="swaied"]').prop('value', $editor.html())
			$editor.trigger('tbwchange')
			$.fn.setCurCaretPos($editor.children()[+i + 1])
		}, 150)
	})
	.on('click', '.btn-add', function (e) {
		let t = $(this).parents('.swaied-block-wrap'),
			i = t.index()

		$.fn.addDefaultBlock(i, '<br>')
		$.fn.setCurPlaceholderPos(i + 1, 'show')
		// showEditBlock($(this))
	})
	.on('click', '.swaied-form-submit', function (e) {
		e.preventDefault()
		swaiedFormSubmit($(this))
	})
	.on('click', '.btn-continue-writing', function () {
		$.fn.hideShowFormBlock(0, 0, 0, 0, 'hide', false)
		$.fn.hideShowFormBlockGen(true)
	})
	.on('click', '.swaied-aiwrite-form-generation .aiwrite-replace', function () {
		let swaidBlockForm = $(document).find('.swaied-block-form'),
			i = swaidBlockForm.attr('data-insert-index'),
			text = $(this)
				.parents('.swaied-aiwrite-form-generation')
				.find('.aiwrite-gen-text')
				.text(),
			blockWrap = $editor.children().eq(i)

		// $.fn.addDefaultBlock(i, text)
		// $.fn.hideShowFormBlock(0, 0, 0, 0, 'hide', false)
		// $.fn.hideShowFormBlockGen(false)

		blockWrap.children().eq(3).html(text)
		$editor.trigger('tbwchange')
		$.fn.setCurCaretPos($editor.children()[i])
		$.fn.setCurPlaceholderPos(0, 'hide')
		$.fn.hideShowFormBlock(0, 0, 0, 0, 'hide', false)
		$.fn.hideShowFormBlockGen(false)

		setTimeout(() => {
			// const selection = window.getSelection()
			// const strongs = $($editor.children()[+i + 1].lastChild)
			// if (selection.rangeCount > 0) {
			// 	selection.removeAllRanges()
			// }
			// for (const node of strongs) {
			// 	const range = document.createRange()
			// 	range.selectNode(node)
			// 	selection.addRange(range)
			// }
			// fixed
			// let selection = window.getSelection()
			// let text = $($editor.children()[+i + 1].lastChild)
			// // if (selection.rangeCount > 0) {
			// selection.removeAllRanges()
			// // }
			// for (node of text) {
			// 	let range = document.createRange()
			// 	range.selectNode(node)
			// 	selection.addRange(range)
			// }
			// setCaret($editor.children()[+i + 1])
		}, 150)
	})
	.on('click', '.btn-aiwrite', function () {
		// $editor.swaied('getRange') // Get selection range contains a JavaScript range
		// $editor.swaied('getRangeText') // Get last saved range text
		// $editor.swaied('restoreRange') // Restore last saved range
		// $editor.swaied('saveRange') // Save current range
		// $editor.swaied('restoreRange')
		// $editor.swaied('saveRange')
		// $editor.trigger('tbwchange')
		$(this).hide()
		let aiwriteFormChoice = $(document).find('.aiwrite-form-choice')

		aiwriteFormChoice
			.css({
				top: $aiwritePos.top,
				// left: $aiwritePos.left + 20,
			})
			.show()
		setTimeout(() => {
			aiwriteFormChoice.addClass('show')
		}, 10)
	})
	.on('click', '.btn-improve-write', function (e) {
		let t = $(this),
			mainParent = t.parents('.aiwrite-form-choice'),
			aiwriteFormGen = $(document)
				.find('.aiwrite-form-generation')
				.addClass('show')

		mainParent.find('.dropdown-menu').removeClass('dropdown-open-active')
		mainParent.removeClass('show')
		setTimeout(() => {
			mainParent.find('.dropdown-menu').hide()
			mainParent.hide()
		}, 160)

		aiwriteFormGen
			.css({
				top: $aiwritePos.top,
				// left: $aiwritePos.left + 20,
			})
			.show()
		setTimeout(() => {
			aiwriteFormGen.addClass('show')
		}, 160)

		setTimeout(() => {
			aiwriteFormGen.find('.aiwrite-form-buttons').addClass('show')
			aiwriteFormGen.find('.aiwrite-loading').hide()
			aiwriteFormGen.find('.aiwrite-notice').hide()
			aiwriteFormGen
				.find('.aiwrite-gen-text')
				.text(
					'These projects utilize unused rooftop spaces to grow fruits, vegetables, and herbs. They not only provide fresh produce but also help reduce urban heat islands and improve air quality. Participating in or supporting these initiatives can make a tangible difference in urban sustainability.'
				)
		}, 1500)
	})
	.on('click', '.aiwrite-form', function (e) {
		$editor.swaied('restoreRange')
		// e.preventDefault()
		// e.stopPropagation()
		// if ($(e.target).not('.aiwrite-replace') == 0) {
		// 	console.log('true')
		// } else {
		// 	//$editor.swaied('restoreRange')
		// 	e.preventDefault()
		// 	e.propo
		// }

		// console.log($(e.target).not('.aiwrite-replace'))
	})
	.on('click', '.aiwrite-form-generation .aiwrite-replace', function () {
		let t = $(this).parents('.aiwrite-form-generation'),
			genText = t.find('.aiwrite-gen-text')

		// $editor.swaied('restoreRange')
		// $(window.getSelection().focusNode.parentNode).trigger('focus')
		// $editor.swaied('saveRange')
		// $editor.focus()
		// $editor.swaied('restoreRange')
		// $editor.swaied('getRange')
		$editor.swaied('execCmd', {
			cmd: 'insertText',
			param: genText.text(),
			forceCss: false,
		})

		t.removeClass('show')
		setTimeout(() => {
			window.getSelection().removeAllRanges()
			t.find('.aiwrite-form-buttons').removeClass('show')
			t.find('.aiwrite-loading').show()
			t.find('.aiwrite-notice').show()
			genText.text('')
			t.hide()
		}, 160)
	})
	.on(
		'keypress',
		'.swaied-metadata p, .swaied-metadata h1, .swaied-block-wrap h1',
		function (e) {
			if (e.which == 13) {
				e.preventDefault()
			}
		}
	)
	.on('keydown', '.swaied-block-form', function (e) {
		if (e.keyCode == 27) {
			let swaiedBlockForm = $(document).find('.swaied-block-form'),
				i = swaiedBlockForm.attr('data-insert-index')

			$.fn.hideShowFormBlock(i, 0, 0, 0, 'hide', false)
			// $.fn.hideShowFormBlockGen(false)
			$.fn.setCurCaretPos($editor.children()[i])

			// console.log('ESC')
		}

		if (e.keyCode == 13) {
			e.preventDefault()
			let btnSubmit = $(document).find('.swaied-block-form .swaied-form-submit')
			swaiedFormSubmit(btnSubmit)
		}
	})
	.on('keyup', '.swaied-metadata p', function (e) {
		$(this).parent().find('.badge-count span').text($(this).text().length)
		$.fn.reCalcSeoScore()
	})
	.on('mousedown', '.btn-drag', function (e) {
		$editor.addClass('hovered-false')
	})
	.on('mouseup', function (e) {
		$editor.removeClass('hovered-false')
	})
	.on('mousedown', function (e) {
		if (!$(e.target).closest('.dropdown-container').length) {
			hideAllDropdownMenu()
		}

		if (!$(e.target).closest('.dropdown-container-lvl2').length) {
			hideAllDropdownMenuLvl2()
		}

		if (!$(e.target).closest('.btn-aiwrite').length) {
			$(document).find('.btn-aiwrite').hide(100)
		}

		if (
			!$(e.target).closest('.swaied-block-form').length &&
			// $editor.children().length != 0 &&
			$(document).find('.swaied-block-form').hasClass('show')
		) {
			$.fn.hideShowFormBlock(0, 0, 0, 0, 'hide', false)
		}

		if (
			!$(e.target).closest('.swaied-aiwrite-form-generation').length &&
			// $editor.children().length != 0 &&
			$(document).find('.swaied-aiwrite-form-generation').hasClass('show')
		) {
			$.fn.hideShowFormBlockGen(false)
		}

		if (
			!$(e.target).closest('.aiwrite-form-generation').length &&
			$(document).find('.aiwrite-form-generation').hasClass('show')
		) {
			let t = $(document).find('.aiwrite-form-generation'),
				genText = t.find('.aiwrite-gen-text')

			t.removeClass('show')
			setTimeout(() => {
				t.find('.aiwrite-form-buttons').removeClass('show')
				t.find('.aiwrite-loading').show()
				t.find('.aiwrite-notice').show()
				genText.text('')
				t.hide()
			}, 160)
		}

		if (
			!$(e.target).closest('.aiwrite-form-choice').length &&
			$(document).find('.aiwrite-form-choice').hasClass('show')
		) {
			let t = $(document).find('.aiwrite-form-choice'),
				dropdown = t.find('.dropdown-menu')

			dropdown.removeClass('dropdown-open-active')
			t.removeClass('show')
			setTimeout(() => {
				dropdown.hide()
				t.hide()
			}, 160)
		}

		if (!$(e.target).closest('.image-gen-form').length) {
			$(document).find('.image-gen-form').removeClass('show-form')
			setTimeout(() => {
				$(document).find('.image-gen-form').hide()
			}, 150)
		}

		if (
			!$(e.target).closest('.dropdown-container.dropdown-interaction').length
		) {
			$(document).find('.image-gen-form').removeClass('no-events')
		}
	})
	.on('mousedown', '#swaied .swaied-block-wrap', function (e) {
		let t = $(this),
			i = t.index(),
			text = t.text().length,
			dragActive = $editor.hasClass('hovered-false')

		if (text == 0 && !dragActive) {
			$.fn.setCurPlaceholderPos(i, 'show')
		} else {
			$.fn.setCurPlaceholderPos(i, 'hide')
		}
	})
	.on(
		'click',
		'.swaied-historyUndo-button, .swaied-historyRedo-button',
		function (e) {
			$.fn.setCurPlaceholderPos(0, 'hide')
		}
	)
	.on('click', '#addImage', function (e) {
		const imgID = Date.now()
		const promptText =
			'Show a bright, modern apartment building with a pool and outdoor seating area surrounded by lush greenery. In the foreground, show a person lounging in a comfortable chair with a book, enjoying the peaceful ambiance. The building should have large windows and balconies overlooking the city skyline and ocean view in the distance.'
		let t = $(this).parents('.swaied-block-wrap'),
			i = t.attr('data-insert-index'),
			blockWrap = $editor.children().eq(i),
			imageAi =
				'<div class="image-gen">' +
				'<img class="image-gen-ai" ' +
				'id="imgGenAi' +
				imgID +
				'"' +
				'src="https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/e66e4/MainAfter.jpg" ' +
				'alt="image-gen-ai">' +
				'</div>',
			imageAiForm =
				'<div class="image-gen-form" data-target-id="imgGenAi' +
				imgID +
				'">' +
				'<div class="image-gen-prompt-preview">' +
				'<span class="prompt-preview-text">' +
				promptText +
				'</span>' +
				'<button class="btn btn-default btn-reload" aria-label="Update Image"><svg><use xlink:href="#swaied-redo"></use></svg></button>' +
				'</div>' +
				'<div class="image-gen-prompt-form">' +
				'<div class="form-control form-textarea">' +
				'<span class="label">Prompt</span>' +
				'<span class="count"><span>' +
				promptText.length +
				'</span>/1000</span>' +
				'<textarea name="' +
				imgID +
				'">' +
				promptText +
				'</textarea>' +
				'</div>' +
				'<div class="form-control form-select">' +
				'<span class="label">Image Style</span>' +
				'<div class="dropdown-container dropdown-style">' +
				'<button class="btn btn-default btn-dropdown-toggle">' +
				'<span>Style 1</span>' +
				'<svg><use xlink:href="#swaied-dropdown"></use></svg>' +
				'</button>' +
				'<div class="dropdown-menu">' +
				'<button class="btn btn-style active">' +
				'Style 1' +
				'</button>' +
				'<button class="btn btn-style">' +
				'Style 2' +
				'</button>' +
				'<button class="btn btn-style">' +
				'Style 3' +
				'</button>' +
				'<button class="btn btn-style">' +
				'Style 4' +
				'</button>' +
				'</div>' +
				'</div>' +
				'</div>' +
				'<div class="form-control form-select">' +
				'<span class="label">Image Size</span>' +
				'<div class="dropdown-container dropdown-size">' +
				'<button class="btn btn-default btn-dropdown-toggle">' +
				'<span>1344×768 (16:9)</span>' +
				'<svg><use xlink:href="#swaied-dropdown"></use></svg>' +
				'</button>' +
				'<div class="dropdown-menu">' +
				'<button class="btn btn-size">' +
				'1280×768 (16:9)' +
				'</button>' +
				'<button class="btn btn-size active">' +
				'1344×768 (16:9)' +
				'</button>' +
				'<button class="btn btn-size">' +
				'1920×768 (16:9)' +
				'</button>' +
				'<button class="btn btn-size">' +
				'2480×768 (16:9)' +
				'</button>' +
				'</div>' +
				'</div>' +
				'</div>' +
				'<div class="form-control form-notice">' +
				'<span class="label">Important!</span>' +
				'<span class="notice-text">It costs 100 words to regenerate one image</span>' +
				'</div>' +
				'<div class="form-control form-btns">' +
				'<button class="btn btn-primary btn-submit">' +
				'Generate New Image' +
				'</button>' +
				'<button class="btn btn-default btn-cancel">' +
				'Cancel' +
				'</button>' +
				'</div>' +
				'</div>' +
				'</div>'
		$(document).find('.swaied-outside-block').append(imageAiForm)
		blockWrap.prop('contenteditable', false)
		blockWrap.children().eq(3).html(imageAi)
		$editor.trigger('tbwchange')
		$.fn.setCurPlaceholderPos(i, 'hide')
		$.fn.hideShowFormBlock(i, 0, 0, 0, 'hide', false)
	})
	.on('mouseenter', '.image-gen', function (e) {
		let t = $(this),
			imgID = t.find('img').attr('id'),
			blockWrap = t.parents('.swaied-block-wrap'),
			imageForm = $(document).find(
				'.image-gen-form[data-target-id="' + imgID + '"]'
			),
			dropdownMore = blockWrap
				.find('.dropdown-interaction .btn-more')
				.hasClass('active')

		imageAiFormBlock(imageForm)
		imageForm.show()

		if (dropdownMore) {
			imageForm.addClass('no-events')
		} else {
			imageForm.removeClass('no-events')
		}
	})
	.on('mouseenter', '.image-gen-form', function (e) {
		let t = $(this),
			imgID = t.attr('data-target-id'),
			img = $(document).find('img#' + imgID),
			blockWrap = img.parents('.swaied-block-wrap'),
			dropdownMore = blockWrap
				.find('.dropdown-interaction .btn-more')
				.hasClass('active')
		blockWrap.addClass('hovered')

		if (dropdownMore) {
			t.addClass('no-events')
		} else {
			t.removeClass('no-events')
		}
	})
	.on('click', '.prompt-preview-text', function (e) {
		let t = $(this).parents('.image-gen-form')
		t.addClass('show-form')
	})
	.on('keyup', '.image-gen-prompt-form textarea', function (e) {
		let t = $(this).parents('.image-gen-form'),
			previewText = t.find('.prompt-preview-text'),
			promptCount = t.find('.count span'),
			value = t.find('textarea').val()

		previewText.text(value)
		promptCount.text(value.length)
	})
	.on(
		'click',
		'.image-gen-form .btn-submit, .image-gen-form .btn-cancel',
		function (e) {
			let t = $(this).parents('.image-gen-form')
			t.removeClass('show-form')
		}
	)
	.on(
		'click',
		'.image-gen-form .btn-style, .image-gen-form .btn-size',
		function (e) {
			let t = $(this),
				mainParent = $(this).parents('.dropdown-container'),
				parent = $(this).parents('.dropdown-menu')

			parent.find('.btn').removeClass('active')
			t.addClass('active')
			mainParent.children('.btn').children('span').text(t.text())
		}
	)
	.on('click', '#addVideo', function (e) {
		let t = $(this).parents('.swaied-block-wrap'),
			i = t.attr('data-insert-index'),
			blockWrap = $editor.children().eq(i),
			video =
				'<iframe ' +
				'width="100%" ' +
				'height="475" ' +
				'src="https://www.youtube.com/embed/cC9r0jHF-Fw?si=TVN9_EUn4_nN29iA" ' +
				'title="YouTube video player" ' +
				'frameborder="0" ' +
				'allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ' +
				'referrerpolicy="strict-origin-when-cross-origin" ' +
				'allowfullscreen>' +
				'</iframe>'

		blockWrap.prop('contenteditable', false)
		blockWrap.children().eq(3).html(video)
		$editor.trigger('tbwchange')
		$.fn.setCurPlaceholderPos(i, 'hide')
		$.fn.hideShowFormBlock(i, 0, 0, 0, 'hide', false)
	})
	.on(
		'click',
		'.swaied-h1-button, .swaied-h2-button, .swaied-h3-button, .swaied-blockquote-button, .swaied-modal-submit, .swaied-orderedList-button, .swaied-unorderedList-button',
		function (e) {
			let placeHolder = $(document).find('.swaied-placeholder'),
				i = placeHolder.attr('data-target-index')
			$.fn.setCurPlaceholderPos(i, 'show')
		}
	)
	.on('click', '.swaied-modal-submit', function (e) {
		setTimeout(() => {
			let placeHolder = $(document).find('.swaied-placeholder'),
				i = placeHolder.attr('data-target-index')
			$.fn.setCurPlaceholderPos(i, 'show')
		}, 10)
	})

// scroll event
$(window)
	.on('scroll', function () {
		let t = $('#rightFixedBar')
		let $top = $(document).scrollTop()
		if ($top >= 65) {
			t.css({ paddingTop: 0 })
		} else {
			t.css({ paddingTop: 64 - $top })
		}

		// hide
		// $(document).find('.btn-aiwrite').hide(100)
		// $.fn.hideShowFormBlock(0, 0, 0, 0, 'hide', false)
		// $.fn.hideShowFormBlockGen(false)
	})
	.on('resize', function () {
		let placeHolder = $(document).find('.swaied-placeholder'),
			i = placeHolder.attr('data-target-index')
		$.fn.setCurPlaceholderPos(i, 'show')

		let imagesGenAi = $(document).find('.image-gen-form')
		imagesGenAi.each(function(){
			imageAiFormBlock($(this))
		})
	})

function hideShowDropdownMenu(t, d, c) {
	let $parent = t.parent()
	let $dropMenu = $parent.children('.dropdown-menu')

	$(document).find('.btn-dropdown-toggle').not(t).removeClass('active')
	$(document)
		.find('.dropdown-menu')
		.not($dropMenu)
		.removeClass('dropdown-open-active')
	setTimeout(() => {
		$(document).find('.dropdown-menu').not($dropMenu).hide()
	}, 150)

	if (c == 'show') {
		dropdownMenuOpen(t, $parent, $dropMenu, d)
	}

	if (c == 'hide') {
		dropdownMenuClose(t, $parent, $dropMenu)
	}

	if (c == 'both') {
		dropdownMenuOpen(t, $parent, $dropMenu, d)
		dropdownMenuClose(t, $parent, $dropMenu)
	}
}

function dropdownMenuOpen(t, $parent, $dropMenu, d) {
	if (!$parent.hasClass('animate') && !t.hasClass('active')) {
		$parent.addClass('animate')
		t.addClass('active')
		$dropMenu.css('display', d)
		setTimeout(() => {
			$dropMenu.addClass('dropdown-open-active')
		}, 0)
		setTimeout(() => {
			$parent.removeClass('animate')
		}, 150)
	}
}

function dropdownMenuClose(t, $parent, $dropMenu) {
	if (!$parent.hasClass('animate') && t.hasClass('active')) {
		$parent.addClass('animate')
		t.removeClass('active')
		$dropMenu.removeClass('dropdown-open-active')
		setTimeout(() => {
			$dropMenu.hide()
			$parent.removeClass('animate')
		}, 150)
	}
}

function hideAllDropdownMenu() {
	$(document)
		.find('.btn-dropdown-toggle, .btn-dropdown-toggle-lvl2')
		.removeClass('active')
	$(document)
		.find('.dropdown-menu, .dropdown-menu-lvl2')
		.removeClass('dropdown-open-active')
	setTimeout(() => {
		$(document).find('.dropdown-menu, .dropdown-menu-lvl2').hide()
	}, 150)
}

function hideShowDropdownMenuLvl2(t, d, c) {
	let $parent = t.parent()
	let $dropMenu = $parent.children('.dropdown-menu-lvl2')

	$(document).find('.btn-dropdown-toggle-lvl2').not(t).removeClass('active')
	$(document)
		.find('.dropdown-menu-lvl2')
		.not($dropMenu)
		.removeClass('dropdown-open-active')
	setTimeout(() => {
		$(document).find('.dropdown-menu-lvl2').not($dropMenu).hide()
	}, 150)

	if (c == 'show') {
		dropdownMenuOpenLvl2(t, $parent, $dropMenu, d)
	}

	if (c == 'hide') {
		dropdownMenuCloseLvl2(t, $parent, $dropMenu)
	}

	if (c == 'both') {
		dropdownMenuOpenLvl2(t, $parent, $dropMenu, d)
		dropdownMenuCloseLvl2(t, $parent, $dropMenu)
	}
}

function dropdownMenuOpenLvl2(t, $parent, $dropMenu, d) {
	if (!$parent.hasClass('animate') && !t.hasClass('active')) {
		$parent.addClass('animate')
		t.addClass('active')
		$dropMenu.css('display', d)
		setTimeout(() => {
			$dropMenu.addClass('dropdown-open-active')
		}, 0)
		setTimeout(() => {
			$parent.removeClass('animate')
		}, 150)
	}
}

function dropdownMenuCloseLvl2(t, $parent, $dropMenu) {
	if (!$parent.hasClass('animate') && t.hasClass('active')) {
		$parent.addClass('animate')
		t.removeClass('active')
		$dropMenu.removeClass('dropdown-open-active')
		setTimeout(() => {
			$dropMenu.hide()
			$parent.removeClass('animate')
		}, 150)
	}
}

function hideAllDropdownMenuLvl2() {
	$(document).find('.btn-dropdown-toggle-lvl2').removeClass('active')
	$(document).find('.dropdown-menu-lvl2').removeClass('dropdown-open-active')
	setTimeout(() => {
		$(document).find('.dropdown-menu-lvl2').hide()
	}, 150)
}

function showSeoScore(t) {
	$.fn.getSeoScore()

	let $container = t.parents('.sidebar-seo'),
		$dropMenuKeywords = $container.find('.sidebar-item.keywords'),
		$dropMenuSeoScore = $container.find('.sidebar-item.seo-score'),
		items = $dropMenuKeywords.children().not('.loading'),
		loader = $dropMenuKeywords.children('.loading'),
		scoreCount = $dropMenuSeoScore.find('.score-count span'),
		scoreCountData = parseInt(scoreCount.attr('data-count'))

	items.addClass('hidden')
	loader.removeClass('hidden')

	// START: 280 // END: 80 // 100% = 200
	let posG = 280 - scoreCountData * 2
	// START: 7 // END: 266 // 100% = 259
	let posC = (259 * scoreCountData) / 100 + 7

	$dropMenuSeoScore.find('.score svg circle').css({
		'stroke-dashoffset': '280',
	})
	$dropMenuSeoScore.find('.score-cursor').css({
		transform: 'rotateZ(7deg)',
	})
	scoreCount.text('0')

	$container.addClass('animate')
	setTimeout(() => {
		$dropMenuKeywords.removeClass('sidebar-item-active')
	}, 1000)

	setTimeout(() => {
		$dropMenuKeywords.hide()
		items.removeClass('hidden')
		loader.addClass('hidden')
		$dropMenuSeoScore.css('display', 'flex')
	}, 1150)

	setTimeout(() => {
		$dropMenuSeoScore.addClass('sidebar-item-active')
	}, 1200)

	setTimeout(() => {
		$container.removeClass('animate')
		$dropMenuSeoScore.find('.score svg circle').css({
			'stroke-dashoffset': posG,
		})
		$dropMenuSeoScore.find('.score-cursor').css({
			transform: 'rotateZ(' + posC + 'deg)',
		})

		$({ Counter: 0 }).animate(
			{
				Counter: scoreCountData,
			},
			{
				duration: 500,
				easing: 'linear',
				step: function () {
					scoreCount.text(Math.ceil(this.Counter))
				},
			}
		)
	}, 1500)
}

function showEditBlock(t) {
	// e.preventDefault()
	window.getSelection().removeAllRanges()
	let i = t.parents('.swaied-block-wrap').index(),
		p = $editor.children()[i],
		top = $(p).offset().top,
		left = $(p).offset().left,
		h = $(p).outerHeight(),
		w = $(p).outerWidth()

	// $.fn.hideShowFormBlock(i, top + h + 24, left, w, 'show', false)
	$.fn.hideShowFormBlock(i, top, left, w, 'show', false)

	// $editor.swaied('getRange') // Get selection range contains a JavaScript range
	// $editor.swaied('getRangeText') // Get last saved range text
	// $editor.swaied('restoreRange') // Restore last saved range
	// $editor.swaied('saveRange') // Save current range
	// console.log($editor.swaied('getRange'))
}

function swaiedFormSubmit($this) {
	let t = $this.parents('.swaied-block-form'),
		i = t.attr('data-insert-index'),
		input = t.find('input[name="swaiedTextInput"]').val(),
		blockWrap = $editor.children().eq(i)

	if (input != '') {
		if (blockWrap.text().length > 0 || blockWrap.find('img').length > 0) {
			$.fn.addDefaultBlock(i, input)
			$.fn.hideShowFormBlock(0, 0, 0, 0, 'hide', false)
		} else {
			blockWrap.children().eq(3).html(input)
			$editor.trigger('tbwchange')
			$.fn.setCurCaretPos($editor.children()[i])
			$.fn.setCurPlaceholderPos(0, 'hide')
			$.fn.hideShowFormBlock(0, 0, 0, 0, 'hide', false)
		}
	}
	// $editor.swaied('restoreRange')
	// console.log($editor.swaied('getRange'))
}

// async function setClipboard(html) {
// 	const type = 'text/html'
// 	const blob = new Blob([html], { type })
// 	const data = [new ClipboardItem({ [type]: blob })]
// 	await navigator.clipboard.write(data)
// }

function copyToClipboard(html) {
	const clipboardItem = new ClipboardItem({
		'text/html': new Blob([html], { type: 'text/html' }),
		'text/plain': new Blob([html], { type: 'text/plain' }),
	})

	navigator.clipboard.write([clipboardItem]).then(
		_ => console.log('clipboard.write() Ok'),
		error => alert(error)
	)
}

function imageAiFormBlock(t) {
	let imgID = t.attr('data-target-id'),
		img = $(document).find('img#' + imgID),
		top = img.offset().top,
		h = img.height(),
		w = img.width()

	t.css({
		top: top - 124,
		height: h,
		width: w,
	})
}
