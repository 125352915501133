{
	"analysis": {
		"0": {
			"name": "Words",
			"min": 2000,
			"max": 2500
		},
		"1": {
			"name": "Headings",
			"min": 35,
			"max": 37
		},
		"2": {
			"name": "Paragraphs",
			"min": 70,
			"max": 75
		},
		"3": {
			"name": "Images",
			"min": 4,
			"max": 7
		}
	},
	"keywords": {
		"0": {
			"name": "midi",
			"min": 40,
			"max": 43
		},
		"1": {
			"name": "digital music",
			"min": 27,
			"max": 30
		},
		"2": {
			"name": "audio files",
			"min": 10,
			"max": 14
		},
		"3": {
			"name": "musical instruments",
			"min": 8,
			"max": 10
		},
		"4": {
			"name": "software",
			"min": 5,
			"max": 7
		},
		"5": {
			"name": "music software",
			"min": 5,
			"max": 7
		},
		"6": {
			"name": "virtual instruments",
			"min": 3,
			"max": 5
		},
		"7": {
			"name": "audio production",
			"min": 2,
			"max": 4
		}
	}
}
