$.fn.createSourcelinksBlock = function () {
	let swaiedSourcelinksBlock = $('<div/>', {
		class: 'swaied-sourcelinks',
		html:
			'<div class="swaied-block-sourcelinks" data-block="sourcelinks">' +
				'<div class="swaied-block-wrap">' +
					'<span class="badge-title">' +
						'Source Links' +
					'</span>' +
					'<span class="badge-count">' +
						'<span>5</span>' +
						'Links' +
					'</span>' +
					'<div class="link-item">' +
						'<div class="link-index">' +
							'<svg><use xlink:href="#swaied-hash"></use></svg>' +
							'<span>' +
								'1.' +
							'</span>' +
						'</div>' +
						'<div class="link-desc">' +
							'<div class="link-icon icon-1">' +
								'<svg><use xlink:href="#swaied-url-icon-1"></use></svg>' +
							'</div>' +
							'<div class="link-text">' +
								'<span class="title">TurboGamer</span>' +
								'<span class="desc">A Tree-Approach Pauli Decomposition Algorithm with Application to Quantum Computing</span>' +
							'</div>' +
						'</div>' +
						'<a href="#" class="btn btn-link" aria-label="TurboGamer">' +
							'<svg><use xlink:href="#swaied-url"></use></svg>' +
						'</a>' +
					'</div>' +
					'<div class="link-item">' +
						'<div class="link-index">' +
							'<svg><use xlink:href="#swaied-hash"></use></svg>' +
							'<span>' +
								'2.' +
							'</span>' +
						'</div>' +
						'<div class="link-desc">' +
							'<div class="link-icon icon-2">' +
								'<svg><use xlink:href="#swaied-url-icon-2"></use></svg>' +
							'</div>' +
							'<div class="link-text">' +
								'<span class="title">TurboGamer</span>' +
								'<span class="desc">A Tree-Approach Pauli Decomposition Algorithm with Application to Quantum Computing</span>' +
							'</div>' +
						'</div>' +
						'<a href="#" class="btn btn-link" aria-label="TurboGamer">' +
							'<svg><use xlink:href="#swaied-url"></use></svg>' +
						'</a>' +
					'</div>' +
					'<div class="link-item">' +
						'<div class="link-index">' +
							'<svg><use xlink:href="#swaied-hash"></use></svg>' +
							'<span>' +
								'3.' +
							'</span>' +
						'</div>' +
						'<div class="link-desc">' +
							'<div class="link-icon icon-3">' +
								'<svg><use xlink:href="#swaied-url-icon-3"></use></svg>' +
							'</div>' +
							'<div class="link-text">' +
								'<span class="title">TurboGamer</span>' +
								'<span class="desc">A Tree-Approach Pauli Decomposition Algorithm with Application to Quantum Computing</span>' +
							'</div>' +
						'</div>' +
						'<a href="#" class="btn btn-link" aria-label="BlueDot">' +
							'<svg><use xlink:href="#swaied-url"></use></svg>' +
						'</a>' +
					'</div>' +
					'<div class="link-item">' +
						'<div class="link-index">' +
							'<svg><use xlink:href="#swaied-hash"></use></svg>' +
							'<span>' +
								'4.' +
							'</span>' +
						'</div>' +
						'<div class="link-desc">' +
							'<div class="link-icon icon-1">' +
								'<svg><use xlink:href="#swaied-url-icon-1"></use></svg>' +
							'</div>' +
							'<div class="link-text">' +
								'<span class="title">TurboGamer</span>' +
								'<span class="desc">A Tree-Approach Pauli Decomposition Algorithm with Application to Quantum Computing</span>' +
							'</div>' +
						'</div>' +
						'<a href="#" class="btn btn-link" aria-label="TurboGamer">' +
							'<svg><use xlink:href="#swaied-url"></use></svg>' +
						'</a>' +
					'</div>' +
					'<div class="link-item">' +
						'<div class="link-index">' +
							'<svg><use xlink:href="#swaied-hash"></use></svg>' +
							'<span>' +
								'5.' +
							'</span>' +
						'</div>' +
						'<div class="link-desc">' +
							'<div class="link-icon icon-1">' +
								'<svg><use xlink:href="#swaied-url-icon-1"></use></svg>' +
							'</div>' +
							'<div class="link-text">' +
								'<span class="title">TurboGamer</span>' +
								'<span class="desc">A Tree-Approach Pauli Decomposition Algorithm with Application to Quantum Computing</span>' +
							'</div>' +
						'</div>' +
						'<a href="#" class="btn btn-link" aria-label="TurboGamer">' +
							'<svg><use xlink:href="#swaied-url"></use></svg>' +
						'</a>' +
					'</div>' +
				'</div>' +
			'</div>',
	})

	swaiedSourcelinksBlock.insertAfter($editor)
}