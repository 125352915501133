$.fn.addDefaultBlock = function (i, html) {
	let defaultBlock = $('<div/>', {
		class: 'swaied-block-wrap',
		contenteditable: true,
		html:
			'<button class="btn btn-default btn-drag" tabindex="-1" contenteditable="false" aria-label="Drag"><svg><use xlink:href="#swaied-drag"></use></svg></button>' +
			'<div class="dropdown-container dropdown-interaction" contenteditable="false">' +
			'<button class="btn btn-default btn-more btn-dropdown-toggle" tabindex="-1" aria-label="More" contenteditable="false"><svg><use xlink:href="#swaied-three-dots"></use></svg></button>' +
			'<div class="dropdown-menu">' +
			'<button class="btn btn-duplicate-block" tabindex="-1" contenteditable="false" data-placeholder="Duplicate"><svg><use xlink:href="#swaied-duplicate"></use></svg></button>' +
			'<button class="btn btn-copy-block" tabindex="-1" contenteditable="false" data-placeholder="Copy"><svg><use xlink:href="#swaied-copy"></use></svg></button>' +
			'<button class="btn btn-remove-block" tabindex="-1" contenteditable="false" data-placeholder="Delete"><svg><use xlink:href="#swaied-delete"></use></svg></button>' +
			'</div>' +
			'</div>' +
			'<button class="btn btn-default btn-add" tabindex="-1" contenteditable="false" aria-label="Add block"><svg><use xlink:href="#swaied-add"></use></svg></button>' +
			'<p>' +
			html +
			'</p>',
	})

	if (i < 0) {
		$editor.prop('contenteditable', true)
		$editor.html(defaultBlock)
		$(document).find('textarea[name="swaied"]').prop('value', $editor.html())
		$editor.trigger('tbwchange')
		$.fn.setCurCaretPos($editor.children()[+i + 1])
	} else {
		$editor.prop('contenteditable', true)
		defaultBlock.insertAfter($editor.children()[i])
		$(document).find('textarea[name="swaied"]').prop('value', $editor.html())
		$editor.trigger('tbwchange')
		$.fn.setCurCaretPos($editor.children()[+i + 1])
	}
}
