// json data example
import SeoData from './seodata.json'

$.fn.hideShowFormBlock = function (i, top, left, width, hideShow, start) {
	let swaiedBlockForm = $(document).find('.swaied-block-form'),
		swaiedFormGen = $(document).find('.swaied-aiwrite-form-generation')

	if (start == true && hideShow == 'show') {
		swaiedBlockForm
			.attr('data-insert-index', -1)
			.css({
				top: $editor.offset().top,
				// left: $editor.offset().left + 4,
				// width: $editor.outerWidth() - 8,
			})
			.show()
		setTimeout(() => {
			swaiedBlockForm.addClass('show')
		}, 10)

		setTimeout(() => {
			swaiedBlockForm.find('input[name="swaiedTextInput"]').focus()
		}, 160)

		swaiedFormGen.css({
			top: $editor.offset().top,
		})
	} else if (start == false && hideShow == 'show') {
		swaiedBlockForm
			.attr('data-insert-index', i)
			.css({
				top: top - 124,
				// left: left,
				// width: width,
			})
			.show()
		setTimeout(() => {
			swaiedBlockForm.addClass('show')
		}, 10)

		setTimeout(() => {
			swaiedBlockForm.find('input[name="swaiedTextInput"]').focus()
		}, 160)

		swaiedFormGen.css({
			top: top - 124,
		})
	} else {
		swaiedBlockForm.removeClass('show')
		setTimeout(() => {
			swaiedBlockForm.hide()
			swaiedBlockForm.find('input[name="swaiedTextInput"]').prop('value', '')
		}, 160)
	}
}

$.fn.hideShowFormBlockGen = function (hideShow) {
	let swaiedFormGen = $(document).find('.swaied-aiwrite-form-generation')

	if (hideShow) {
		swaiedFormGen.show()
		setTimeout(() => {
			swaiedFormGen.addClass('show')
		}, 10)

		setTimeout(() => {
			swaiedFormGen.find('.aiwrite-form-buttons').addClass('show')
			swaiedFormGen.find('.aiwrite-loading').hide()
			swaiedFormGen.find('.aiwrite-notice').hide()
			swaiedFormGen
				.find('.aiwrite-gen-text')
				.text(
					'These projects utilize unused rooftop spaces to grow fruits, vegetables, and herbs. They not only provide fresh produce but also help reduce urban heat islands and improve air quality. Participating in or supporting these initiatives can make a tangible difference in urban sustainability.'
				)
		}, 1500)
	} else {
		swaiedFormGen.removeClass('show')
		setTimeout(() => {
			swaiedFormGen.find('.aiwrite-form-buttons').removeClass('show')
			swaiedFormGen.find('.aiwrite-loading').show()
			swaiedFormGen.find('.aiwrite-notice').show()
			swaiedFormGen.find('.aiwrite-gen-text').text('')
			swaiedFormGen.hide()
		}, 160)
	}
}

$.fn.setCurCaretPos = function (item) {
	let el = item.lastChild.childNodes[0],
		pos = el.length,
		range = document.createRange(),
		sel = window.getSelection()

	range.setStart(el, pos)
	range.collapse(true)

	sel.removeAllRanges()
	sel.addRange(range)
}

$.fn.checkCheckboxTargets = function () {
	// check metadata on the page
	let metaDataContainer = $(document).find('.swaied-metadata'),
		metaDataCheckbox = $(document).find('#checkbox-metatags')
	if (
		metaDataContainer.length > 0 &&
		metaDataContainer.children().is(':visible')
	) {
		metaDataCheckbox.prop('checked', true)
	} else if (
		metaDataContainer.length > 0 &&
		metaDataContainer.children().is(':hidden')
	) {
		metaDataCheckbox.prop('checked', false)
	} else {
		metaDataCheckbox.prop('disabled', true)
	}

	// check sourcelinks on the page
	let sourceLinkContainer = $(document).find('.swaied-sourcelinks'),
		sourceLinkCheckbox = $(document).find('#checkbox-sourcelinks')
	if (
		sourceLinkContainer.length > 0 &&
		sourceLinkContainer.children().is(':visible')
	) {
		sourceLinkCheckbox.prop('checked', true)
	} else if (
		sourceLinkContainer.length > 0 &&
		sourceLinkContainer.children().is(':hidden')
	) {
		sourceLinkCheckbox.prop('checked', false)
	} else {
		sourceLinkCheckbox.prop('disabled', true)
	}

	// check citation on the page
	let citationsTags = $(document).find('sup.citation'),
		citationsCheckbox = $(document).find('#checkbox-citations')
	if (citationsTags.length > 0 && citationsTags.children().is(':visible')) {
		citationsCheckbox.prop('checked', true)
	} else if (
		citationsTags.length > 0 &&
		citationsTags.children().is(':hidden')
	) {
		citationsCheckbox.prop('checked', false)
	} else {
		citationsCheckbox.prop('disabled', true)
	}
}

$.fn.setCurPlaceholderPos = function (i, show) {
	let swaiedPlaceholder = $(document).find('.swaied-placeholder')

	if (show == 'hide') {
		swaiedPlaceholder.attr('data-target-index', i)
		swaiedPlaceholder.hide()
	} else {
		let t = $($editor.children()[i]),
			text = t.children().eq(3).text().length,
			h = t.children().eq(3).outerHeight(),
			tags = t.find('img, iframe, ul, ol').length,
			blockquote = (t.find('blockquote').length > 0) ? 15 : 0
		
		if (show == 'show' && tags == 0 && text == 0) {
			swaiedPlaceholder.attr('data-target-index', i)
			swaiedPlaceholder
				.css({
					// top: t.offset().top,
					// left: t.offset().left
					top: t.offset().top - 107, // 440 - 333
					left: 61 + blockquote, // 269.5
					height: h,
				})
				.show()
		} else {
			swaiedPlaceholder.hide()
		}
	}
}

$.fn.getSeoScore = function () {
	// let analysis = JSON.parse(JSON.stringify(SeoData.analysis))
	const analysis = SeoData.analysis
	const keywords = SeoData.keywords

	// 2 000 * 100% = 200 000
	// 200 000 / 2 500 = 80%
	// 20% * 0.8 = 16%
	// 20% - 16% = 4%

	let contentAnalysisItems = $(document).find(
			'.sidebar-item.seo-score .analysis-items'
		),
		contentKeywordsItems = $(document).find(
			'.sidebar-item.seo-score .keywords-items'
		),
		metaTitle = $(document).find('.swaied-metadata .meta-title p'),
		metaDesc = $(document).find('.swaied-metadata .meta-description p'),
		header1 = $editor.find('h1').text(),
		header2 = $editor.find('h2').text(),
		header3 = $editor.find('h3').text(),
		textBlocks = $editor.find('h1, h2, h3, p, blockquote'),
		img = $editor.find('img'),
		scoreCount = $(document).find('.sidebar-item.seo-score .score-count span'),
		oldScoreCount = scoreCount.attr('data-count'),
		totalScore = 0,
		allText = ''

	textBlocks.each(function () {
		allText += ' ' + $(this).text()
	})
	allText = $.trim(allText)
	allText = allText.replace(/\s{2,}/g, ' ')
	allText = allText.toLowerCase()

	contentAnalysisItems.children().each(function (index) {
		let t = $(this),
			title = t.find('.item-title'),
			score = t.find('.item-score'),
			range = t.find('.item-range'),
			name = analysis[index].name,
			min = analysis[index].min,
			max = analysis[index].max,
			total = 0,
			count = 0,
			percent = 0

		if (allText.length > 0) {
			if (name == 'Words') {
				percent = 20
				// count = 2000
				count = allText.split(' ').length
			}

			if (name == 'Headings') {
				percent = 10
				// count = 35
				// count = $editor.find('h1, h2, h3').length
				count = 0
				$editor.find('h1, h2, h3').each(function () {
					if ($(this).text().length > 0) {
						count++
					}
				})
			}

			if (name == 'Paragraphs') {
				percent = 5
				// count = 70
				// count = $editor.find('p').length - $editor.find('img').length
				count = 0
				$editor.find('p').each(function(){
					if ($(this).text().length > 0) {
						count++
					}
				})
			}
		} else {
			if (name == 'Words') {
				percent = 20
				count = 0
			}

			if (name == 'Headings') {
				percent = 10
				count = 0
			}

			if (name == 'Paragraphs') {
				percent = 5
				count = 0
			}
		}

		if (name == 'Images') {
			percent = 5
			// count = 7
			count = $editor.find('img').length
		}

		if (count >= min && count <= max) {
			t.attr('class', 'item estimation-green')
			score.html(count + '<svg><use xlink:href="#swaied-check"></use></svg>')
			total = percent
		} else if (count < min) {
			t.attr('class', 'item estimation-red')
			score.html(
				count + '<svg><use xlink:href="#swaied-arrow-up-short"></use></svg>'
			)
			total = (count * 100) / min
			total = (total / 100) * percent
		} else {
			t.attr('class', 'item estimation-red need-down')
			score.html(
				count + '<svg><use xlink:href="#swaied-arrow-up-short"></use></svg>'
			)
			total = (count * 100) / max
			total = (total / 100) * percent
			total = percent - (total - percent)
		}

		title.text(name)
		range.text(min + ' - ' + max)
		totalScore += total
	})

	if (metaTitle.text().length > 0) {
		totalScore += 10
	}

	if (metaDesc.text().length > 0) {
		totalScore += 10
	}

	if (header1.length > 0) {
		totalScore += 10
	}

	if (header2.length > 0) {
		totalScore += 3
	}

	if (header3.length > 0) {
		totalScore += 3
	}

	if (header3.length > 0) {
		totalScore += 3
	}

	img.each(function () {
		let t = $(this)
		if (t.attr('alt') != '' && t.attr('alt') != undefined) {
			totalScore += 2
			return false
		}
	})

	contentKeywordsItems.empty()
	let keywordsPercent = 20,
		keywordsCount = 0,
		keywordsTotal = 0

	for (let key in keywords) {
		if (keywords.hasOwnProperty(key)) {
			keywordsCount++
		}
	}

	for (let key in keywords) {
		if (keywords.hasOwnProperty(key)) {
			let keyword = keywords[key].name,
				min = keywords[key].min,
				max = keywords[key].max,
				keywordColor = '',
				percent = keywordsPercent / keywordsCount,
				total = 0,
				count = 0,
				regex = new RegExp(keyword, 'g')

			if (allText.indexOf(keyword) !== -1) {
				count = allText.match(regex).length
			}

			if (count >= min && count <= max) {
				// green
				keywordColor = 'estimation-green'
				total = percent
			} else if (count < min) {
				if (count <= min / 10) {
					// red
					keywordColor = 'estimation-red'
				} else {
					// yellow
					keywordColor = 'estimation-yellow'
				}
				total = (count * 100) / min
				total = (total / 100) * percent
			} else {
				if (count >= max * 0.1 + max) {
					// red
					keywordColor = 'estimation-red'
				} else {
					// yellow
					keywordColor = 'estimation-yellow'
				}
				total = (count * 100) / max
				total = (total / 100) * percent
				total = percent - (total - percent)
			}

			let keywordContainer =
				'<div class="item ' +
				keywordColor +
				'">' +
				'<span class="name">' +
				keyword +
				'</span>' +
				'<span class="count">' +
				count +
				'/' +
				min +
				'-' +
				max +
				'</span>' +
				'</div>'
			contentKeywordsItems.append(keywordContainer)
			keywordsTotal += total
		}
	}

	totalScore += keywordsTotal
	scoreCount.attr('data-count', totalScore)
	return oldScoreCount
	// console.log(totalScore)
}

$.fn.reCalcSeoScore = function () {
	// let oldScoreCount = $.fn.getSeoScore()
	let oldScoreCount = Math.round($.fn.getSeoScore())
	let $container = $(document).find('.sidebar-seo'),
		$dropMenuSeoScore = $container.find('.sidebar-item.seo-score'),
		scoreCount = $dropMenuSeoScore.find('.score-count span'),
		scoreCountData = parseInt(scoreCount.attr('data-count'))

	scoreCountData = Math.round(scoreCountData)
	// START: 280 // END: 80 // 100% = 200
	let posG = 280 - scoreCountData * 2
	// START: 7 // END: 266 // 100% = 259
	let posC = (259 * scoreCountData) / 100 + 7

	$dropMenuSeoScore.find('.score svg circle').css({
		'stroke-dashoffset': posG,
	})
	$dropMenuSeoScore.find('.score-cursor').css({
		transform: 'rotateZ(' + posC + 'deg)',
	})

	$({ Counter: oldScoreCount }).animate(
		{
			Counter: scoreCountData,
		},
		{
			duration: 500,
			easing: 'linear',
			step: function () {
				scoreCount.text(Math.ceil(this.Counter))
			},
		}
	)
}
