// scrollbar
import SimpleBar from 'simplebar'

// custom trumbowyg script
import './custom.trumbowyg.js'
import './custom.trumbowyg.history.js'
// import './custom.trumbowyg.cleanpaste.js'

// drag for editor
import Sortable, { AutoScroll } from 'sortablejs/modular/sortable.core.esm.js'
Sortable.mount(new AutoScroll())

// icons svg pack for editor
import IconsSvg from '../icons/icons.svg'

// global constant
window.$aiwritePos = {}
window.$editor = $('#swaied')

// constant for editor
const editorTags = 'h1, h2, h3, p, blockquote'
const articleData = false

// load article data in editor
if (articleData) {
	// load data
} else {
	// nothing
	let defaultBlock = $('<div/>', {
		class: 'swaied-block-wrap',
		contenteditable: true,
		html:
			'<button class="btn btn-default btn-drag" tabindex="-1" contenteditable="false" aria-label="Drag"><svg><use xlink:href="#swaied-drag"></use></svg></button>' +
			'<div class="dropdown-container dropdown-interaction" contenteditable="false">' +
			'<button class="btn btn-default btn-more btn-dropdown-toggle" tabindex="-1" aria-label="More"><svg><use xlink:href="#swaied-three-dots"></use></svg></button>' +
			'<div class="dropdown-menu">' +
			'<button class="btn btn-duplicate-block" tabindex="-1" contenteditable="false" data-placeholder="Duplicate"><svg><use xlink:href="#swaied-duplicate"></use></svg></button>' +
			'<button class="btn btn-copy-block" tabindex="-1" contenteditable="false" data-placeholder="Copy"><svg><use xlink:href="#swaied-copy"></use></svg></button>' +
			'<button class="btn btn-remove-block" tabindex="-1" contenteditable="false" data-placeholder="Delete"><svg><use xlink:href="#swaied-delete"></use></svg></button>' +
			'</div>' +
			'</div>' +
			'<button class="btn btn-default btn-add" tabindex="-1" contenteditable="false" aria-label="Add block"><svg><use xlink:href="#swaied-add"></use></svg></button>' +
			'<p>' +
			'<br>' +
			// 'Type "/" for menu' +
			'</p>',
	})
	$editor.html(defaultBlock)
}

// selected text for check
let $selectedText = ''

// init editor
$editor
	.swaied({
		mobile: true,
		fixedBtnPane: true,
		// fixedFullWidth: true,
		autogrow: true,
		autogrowOnEnter: true,
		semantic: false,
		removeformatPasted: false,
		resetCss: false,
		// tagClasses: { p: 'ed-title ed-desc products' },
		tagsToKeep: ['img', 'embed', 'span', 'iframe', 'input'],
		tagsToRemove: ['script', 'section'],
		btnsDef: {
			copy: {
				fn: function (e) {
					$editor.swaied('saveRange')
					let text = $editor.swaied('getRangeText')
					navigator.clipboard.writeText(text)
					// console.log(text)
				},
				title: 'Copy',
				ico: 'copy',
			},
			cut: {
				fn: function () {
					$editor.swaied('saveRange')
					let text = $editor.swaied('getRangeText')
					$editor.swaied('execCmd', {
						cmd: 'insertHTML',
						param: '',
						forceCss: false,
					})
					navigator.clipboard.writeText(text)
					// console.log(text)
				},
				title: 'Cut',
				ico: 'cut',
			},
			delete: {
				fn: function () {
					$editor.swaied('execCmd', {
						cmd: 'insertHTML',
						param: '',
						forceCss: false,
					})
				},
				title: 'Delete',
				ico: 'delete',
			},
		},
		btns: [
			['historyUndo', 'historyRedo'],
			['h1', 'h2', 'h3', 'bold', 'italic', 'underline', 'blockquote', 'link'],
			['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
			['unorderedList', 'orderedList'],
			['insertImage', 'removeformat'],
			// ['copy', 'cut', 'delete'],
		],
		minimalLinks: true,
		svgPath: IconsSvg,
		pasteHandlers: [
			function (e) {
				e.preventDefault()
				// let text = e.clipboardData.getData('text/plain')
				// var pasteData = (e.originalEvent || e).clipboardData.getData('text/html')
				// $(pasteData).each(function() {
				// 	console.log($(this))
				// })

				let pasteData = (e.originalEvent || e).clipboardData.getData(
					'text/html'
				)
				let obj = $(pasteData)
				const tags = [
					'H1',
					'H2',
					'H3',
					'P',
					'BLOCKQUOTE',
					'IMG',
					'UL',
					'OL',
					'TABLE',
				]
				let newObj = []
				// console.log(obj)
				createDataForPaste(obj)

				$editor.swaied('saveRange')
				let blockWrap = $($editor.swaied('getRange').startContainer).parents(
					'.swaied-block-wrap'
				)

				// console.log(newObj)
				for (let i in newObj) {
					if (newObj.hasOwnProperty(i)) {
						let item = newObj[i],
							tag = item.nodeName,
							html = ''

						if (tag != 'IMG' && tag != 'UL' && tag != 'OL') {
							$(item)
								.find('span')
								.each(function () {
									$(this)[0].outerHTML = ' '
								})
							$(item).find('sup').removeAttr('style')
							html = $(item)[0].outerHTML
						} else {
							html = '<p>' + $(item)[0].outerHTML + '</p>'
						}

						if (
							blockWrap.find('img').length == 0 &&
							blockWrap.text().length == 0 &&
							i == 0
						) {
							blockWrap.children().eq(3)[0].outerHTML = html
						} else if (i != 'length') {
							pasteBlockWrap(html)
						}
					}
				}
				$editor.trigger('tbwchange')

				function createDataForPaste(object) {
					for (let i in object) {
						if (object.hasOwnProperty(i)) {
							let item = object[i],
								tag = item.nodeName

							if (tag != '#text' && tag != '#comment' && i != 'length') {
								if (tags.includes(tag)) {
									let el = $(item).removeAttr('style')

									newObj.push(el[0])
									// console.log(el[0])
								} else {
									createDataForPaste($(item)[0].childNodes)
									// console.log('disable tag: ' + tag)
								}
							}
						}
					}
				}

				function pasteBlockWrap(html) {
					let defaultBlock = $('<div/>', {
						class: 'swaied-block-wrap',
						contenteditable: true,
						html:
							'<button class="btn btn-default btn-drag" tabindex="-1" contenteditable="false" aria-label="Drag"><svg><use xlink:href="#swaied-drag"></use></svg></button>' +
							'<div class="dropdown-container dropdown-interaction" contenteditable="false">' +
							'<button class="btn btn-default btn-more btn-dropdown-toggle" tabindex="-1" aria-label="More"><svg><use xlink:href="#swaied-three-dots"></use></svg></button>' +
							'<div class="dropdown-menu">' +
							'<button class="btn btn-duplicate-block" tabindex="-1" contenteditable="false" data-placeholder="Duplicate"><svg><use xlink:href="#swaied-duplicate"></use></svg></button>' +
							'<button class="btn btn-copy-block" tabindex="-1" contenteditable="false" data-placeholder="Copy"><svg><use xlink:href="#swaied-copy"></use></svg></button>' +
							'<button class="btn btn-remove-block" tabindex="-1" contenteditable="false" data-placeholder="Delete"><svg><use xlink:href="#swaied-delete"></use></svg></button>' +
							'</div>' +
							'</div>' +
							'<button class="btn btn-default btn-add" tabindex="-1" contenteditable="false" aria-label="Add block"><svg><use xlink:href="#swaied-add"></use></svg></button>' +
							html,
					})
					$editor.append(defaultBlock)
				}
			},
		],
	})
	.on('tbwinit', function () {
		console.log('Started!')

		// $editor.prop('contenteditable', false)
		// $.fn.addDefaultBlock(-1, '<br>')

		// add elements
		$.fn.createMetadataBlock()
		// $.fn.createSourcelinksBlock()
		$.fn.createEditorOutsideBlock()

		// check metadata/sourcelinks/citations on the page
		$.fn.checkCheckboxTargets()

		let dropdownScrollbar = $(document).find(
			'.dropdown-menu-lvl2, .sidebar-item.seo-score'
		)
		dropdownScrollbar.each(function () {
			new SimpleBar($(this)[0], {
				autoHide: false,
			})
		})

		new Sortable($(this)[0], {
			handle: '.btn-drag',
			draggable: '.swaied-block-wrap',
			removeCloneOnHide: true,
			// swap: true,
			// invertSwap: true,
			// swapClass: 'highlight',
			animation: 300,

			// autoscroll
			scroll: true,
			forceAutoScrollFallback: true,
			scrollFn: function (
				offsetX,
				offsetY,
				originalEvent,
				touchEvt,
				hoverTargetEl
			) {
				return 'continue'
			},
			scrollSensitivity: 150,
			scrollSpeed: 25,
			bubbleScroll: false,

			onEnd: function (evt) {
				$editor.find('.swaied-block-wrap').removeClass('hovered')
				$editor.removeClass('hovered-false')
				$(evt.item).addClass('hovered')
			},
		})

		// $('<div class="swaied-start-preview">Click to Start</div>').insertAfter(
		// 	$editor.parent()
		// )

		let swaiedPlaceholder = $('<span/>', {
			class: 'swaied-placeholder',
			html: 'Type "/" for menu',
		})
		swaiedPlaceholder.insertAfter('.swaied-overlay')
		if ($editor.children().length == 1) {
			let t = $($editor.children()[0]),
				text = t.children().eq(3).text(),
				swaiedPlaceholder = $(document).find('.swaied-placeholder')

			if (text.length == 0) {
				$.fn.setCurPlaceholderPos(0, 'show')
			}
		}

		setTimeout(function () {
			$('.swaied').addClass('loaded')
			$(window).scrollTop(0)
			// $.fn.hideShowFormBlock(0, 0, 0, 0, 'show', true)
		}, 150)
	})
	.on('tbwchange', function () {
		console.log('Changed!')

		let sourcelinks = $(document).find('.swaied-sourcelinks'),
			checkboxCitations = $(document).find('#checkbox-citations'),
			checkboxSourcelinks = $(document).find('#checkbox-sourcelinks')

		if ($editor.find('sup').length > 0 && sourcelinks.length == 0) {
			checkboxCitations.prop('checked', true).prop('disabled', false)
			checkboxSourcelinks.prop('checked', true).prop('disabled', false)
			$.fn.createSourcelinksBlock()
		} else if ($editor.find('sup').length == 0) {
			checkboxCitations.prop('checked', false).prop('disabled', true)
			checkboxSourcelinks.prop('checked', false).prop('disabled', true)
			sourcelinks.remove()
		}

		let seoScore = $(document).find('.sidebar-item.seo-score')
		if (seoScore.hasClass('sidebar-item-active')) {
			$.fn.reCalcSeoScore()
		}

		$(this)
			.find('.swaied-block-wrap')
			.each(function () {
				if ($(this).find(editorTags).length == 0) {
					$(this).remove()
					if ($editor.children().length >= 1) {
						$.fn.setCurCaretPos($editor[0].lastChild)
					}
				}
			})

		if ($('.swaied textarea').val() == '') {
			// $.fn.hideShowFormBlock(0, 0, 0, 0, 'show', true)
			// $.fn.addDefaultBlock(-1, 'test text')
		}

		if ($(this).children().length > 0) {
			// $.fn.hideShowFormBlock(0, 0, 0, 0, 'hide', false)
			// $(document).find('.swaied-start-preview').hide()
		} else {
			// $(document).find('.swaied-start-preview').show()
			$.fn.addDefaultBlock(-1, '<br>')
		}

		// if ($editor.attr('contenteditable') == 'true') {
		// 	$editor.prop('contenteditable', false)
		// 	$.fn.setCurCaretPos($editor[0].lastChild)
		// 	console.log('content disable')
		// 	console.log($editor.attr('contenteditable'))
		// }
		//$('textarea').swaied('saveRange')

		// $editor
		// 	.children()
		// 	.not('.swaied-block-wrap')
		// 	.wrap('<div class="swaied-block-wrap" />')

		// setTimeout(() => {
		// let placeHolder = $(document).find('.swaied-placeholder'),
		// 	i = placeHolder.attr('data-target-index')
		// 	$.fn.setCurPlaceholderPos(i, 'show')
		// }, 100);
		let genImgs = $(this).find('.image-gen')
		if (genImgs.length > 0) {
			let dropdownScrollbar = $(document).find('.image-gen-form .dropdown-menu')
			dropdownScrollbar.each(function () {
				if (!$(this).hasClass('scrollbar')) {
					$(this).addClass('scrollbar')
					new SimpleBar($(this)[0], {
						autoHide: false,
					})
				}
			})
		}
	})
	.on('tbwfocus', function (e) {
		console.log('focus')
	})
	.on('tbwblur', function () {
		console.log('blur')
	})
	// testing
	.on('mouseenter', '.swaied-block-wrap', function () {
		$editor.find('.swaied-block-wrap').removeClass('hovered')
		$(this).addClass('hovered')
	})
	.on('mouseleave', function () {
		$editor.find('.swaied-block-wrap').removeClass('hovered')
	})
	// testing
	// .on('keydown', '.swaied-block-wrap', function (e) {
	// 	// console.log('keypress')
	// 	let i = $(this).index()
	// 	let count = $(this).children().eq(3).find('ul, ol').length
	// 	if (e.which == 13 && count == 0) {
	// 		e.preventDefault()
	// 		$.fn.addDefaultBlock(i, '<br>')
	// 		// console.log(e.target)
	// 	}
	// })
	.on('keydown', function (e) {
		// "BACKSPACE" - 8
		// "TAB" - 9
		// "ENTER" - 13
		// "SHIFT" - 16
		// "CTRL" - 17
		// "ALT" - 18
		// "ESC" - 27
		// "DEL" - 46
		// "/" - 191

		let btnAiWrite = $(document).find('.btn-aiwrite')
		btnAiWrite.hide()

		if (
			// e.keyCode != 8
			e.keyCode != 9 &&
			e.keyCode != 13 &&
			e.keyCode != 16 &&
			e.keyCode != 17 &&
			e.keyCode != 18 &&
			e.keyCode != 27 &&
			e.keyCode != 191
		) {
			$.fn.setCurPlaceholderPos(0, 'hide')
		}

		if (e.which == 13) {
			// e.preventDefault()
			$editor.swaied('saveRange')
			let range = $editor.swaied('getRange')
			// $editor.prop('contenteditable', false)

			let el = range.endContainer,
				t = $(el).parents('.swaied-block-wrap'),
				i = t.index(),
				content = t.children().eq(3)

			// console.log(range)
			// console.log(range.endOffset)
			// console.log(el.length)

			if (t.find('ul, ol').length > 0) {
				// console.log('have ul | ol')
			} else {
				e.preventDefault()
				$editor.prop('contenteditable', false)
				if (range.collapsed && range.startOffset == el.length) {
					// console.log('trigger 1')
					$.fn.addDefaultBlock(i, '<br>')
					$.fn.setCurPlaceholderPos(i + 1, 'show')
				} else if (
					range.collapsed &&
					range.startOffset != el.length &&
					content.find('br').length == 0
				) {
					console.log('trigger 2')
					let start = range.startOffset,
						end = el.length,
						text = content.text().substr(start, end)

					if (content.find('img').length > 0 && content.text().length == 0) {
						$.fn.addDefaultBlock(i, '<br>')
						$.fn.setCurPlaceholderPos(i + 1, 'show')
					} else {
						if (start == 0) {
							content.html('<br>')
						} else {
							content.text(content.text().substr(0, start))
						}
						$.fn.addDefaultBlock(i, text)
						// $.fn.setCurPlaceholderPos(i + 1, 'show')
					}
				} else {
					// console.log('trigger 3')
					$.fn.addDefaultBlock(i, '<br>')
					$.fn.setCurPlaceholderPos(i + 1, 'show')
				}
			}
		}

		if (e.keyCode == 191) {
			$editor.swaied('saveRange')
			let range = $editor.swaied('getRange'),
				el = range.endContainer,
				t = $(el).parents('.swaied-block-wrap'),
				i = t.index(),
				top = $(t).offset().top,
				left = $(t).offset().left,
				h = $(t).outerHeight(),
				w = $(t).outerWidth(),
				content = t.children().eq(3)

			if (content.text().length == 0) {
				e.preventDefault()
				$.fn.hideShowFormBlock(i, top, left, w, 'show', false)
			}
		}

		// console.log(e.keyCode)
	})
	.on('keyup', function (e) {
		if (e.keyCode == 8 || e.keyCode == 46) {
			$editor.swaied('saveRange')
			let range = $editor.swaied('getRange'),
				el = range.endContainer,
				t = $(el).parents('.swaied-block-wrap'),
				i = t.index(),
				content = t.children().eq(3),
				count = content.text().length

			if (count > 0) {
				$.fn.setCurPlaceholderPos(i, 'hide')
			} else if (count == 0) {
				$.fn.setCurPlaceholderPos(i, 'show')
			}
		}
		// if (e.ctrlKey && (e.keyCode == 65 || e.keyCode == 97)) {
		// 	// e.preventDefault()
		// 	// $editor.prop('contenteditable', true)
		// 	// setTimeout(() => {
		// 	// 	$editor.attr('contenteditable', false)
		// 	// 	$editor.trigger('tbwfocus')
		// 	// }, 10)
		// 	console.log('ctrl+a pressed')
		// }
		if (
			e.keyCode == 37 ||
			e.keyCode == 38 ||
			e.keyCode == 39 ||
			e.keyCode == 40
		) {
			$editor.swaied('saveRange')
			let range = $editor.swaied('getRange'),
				el = range.endContainer,
				t = $(el).parents('.swaied-block-wrap'),
				i = t.index(),
				content = t.children().eq(3),
				count = content.text().length

			if (count == 0) {
				$.fn.setCurPlaceholderPos(i, 'show')
			}
		}
	})
	.on('mousedown', '.swaied-block-wrap', function (e) {
		// $editor.prop('contenteditable', true)
		// $editor.swaied('saveRange')
		// console.log('mouse down: ' + $editor.swaied('getRange'))
	})
	.on('mouseup', '.swaied-block-wrap', function (e) {
		//$editor.prop('contenteditable', false)
		// console.log('mouse up')
		// $editor.swaied('getRange') // Get selection range contains a JavaScript range
		// $editor.swaied('getRangeText') // Get last saved range text
		// $editor.swaied('restoreRange') // Restore last saved range
		// $editor.swaied('saveRange') // Save current range
		// $editor.find('.swaied-block-wrap').removeClass('selected-text')
		if ($(e.target).parents(editorTags) != 0) {
			setTimeout(() => {
				$editor.swaied('saveRange')
				let selectedText = $editor.swaied('getRangeText')
				let range = $editor.swaied('getRange')
				let btn = $(document).find('.btn-aiwrite')

				if (range != null) {
					let sectionEditor = $(document).find('.section-editor').width()
					let body = $(document).find('.body').width()
					let selectedRect = range.getBoundingClientRect()
					// let top = e.pageY + 16
					let top = e.pageY - 110
					// $(window).scrollTop() + selectedRect.top + selectedRect.height + 10
					let left = e.pageX + 5 - (body + 30 - sectionEditor) / 2

					$aiwritePos.top = selectedRect.top + selectedRect.height - 120
					$aiwritePos.left = $(this).offset().left

					// if (selectedText.length > 0 && $selectedText != selectedText) {
					if (selectedText.length > 0) {
						btn
							.css({
								top: top,
								left: left,
							})
							.show(100)
					}
					// $selectedText = selectedText
					// $(this).addClass('selected-text')
					// } else {
					// 	$selectedText = ''
					// 	$editor.find('.swaied-block-wrap').removeClass('selected-text')
					// }

					// console.log(selectedRect)
				}
			}, 50)
		}
	})
	.on('click', function () {
		// if ($(this).children().length == 0) {
		// 	$.fn.hideShowFormBlock(0, 0, 0, 0, 'show', true)
		// }
	})

// $editor.children().on('mouseenter', function (e) {
// 	console.log(e.target)
// })
